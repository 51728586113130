// Derived from minima
// solarized-ish

$monospace: 'Anonymous Pro', monospace;
$base03:    #002b36;
$base02:    #073642;
$base01:    #586e75;
$base00:    #657b83;
$base0:     #839496;
$base1:     #93a1a1;
$base2:     #eee8d5;
$base3:     #fdf6e3;
$yellow:    #b58900;
$orange:    #cb4b16;
$red:       #dc322f;
$magenta:   #d33682;
$violet:    #6c71c4;
$blue:      #268bd2;
$cyan:      #2aa198;
$green:     #859900;

.highlight {
    pre    { padding: 0.3em 0.5em; }
    code   { font-family: $monospace; }
    color: $base0;
    background: $base03;

    .highlighter-rouge & {
	background: $base02;
    }

    .c     { color: $base01; font-style: italic } // Comment
    .err   { color: $red; background-color: $base3 } // Error
    .k     { font-weight: bold; color: $base1 } // Keyword
    .o     { font-weight: bold } // Operator
    .cm    { color: $base01; font-style: italic } // Comment.Multiline
    .cp    { color: $base01; font-weight: bold } // Comment.Preproc
    .c1    { color: $base01; font-style: italic } // Comment.Single
    .cs    { color: $base01; font-weight: bold; font-style: italic } // Comment.Special
    .gd    { color: $red } // Generic.Deleted
    .gd .x { color: $red; background-color: $base02 } // Generic.Deleted.Specific
    .ge    { font-style: italic } // Generic.Emph
    .gr    { color: $red } // Generic.Error
    .gh    { color: $base1 } // Generic.Heading
    .gi    { color: $green } // Generic.Inserted
    .gi .x { color: $green; background-color: $base02 } // Generic.Inserted.Specific
    .go    { color: $base0 } // Generic.Output
    .gp    { color: $base1 } // Generic.Prompt
    .gs    { font-weight: bold } // Generic.Strong
    .gu    { color: $base1 } // Generic.Subheading
    .gt    { color: $red } // Generic.Traceback
    .kc    { font-weight: bold; color: $base1 } // Keyword.Constant
    .kd    { font-weight: bold; color: $base1 } // Keyword.Declaration
    .kp    { font-weight: bold; color: $base1 } // Keyword.Pseudo
    .kr    { font-weight: bold; color: $base1 } // Keyword.Reserved
    .kt    { font-weight: bold; color: $base1 } // Keyword.Type
    .m     { color: $cyan } // Literal.Number
    .s     { color: $blue } // Literal.String
    .na    { color: $cyan } // Name.Attribute
    .nb    { font-weight: bold; color: $orange } // Name.Builtin
    .nc    { color: $green; font-weight: bold } // Name.Class
    .no    { color: $magenta } // Name.Constant
    .ni    { color: $violet } // Name.Entity
    .ne    { color: $red; font-weight: bold } // Name.Exception
    .nf    { color: $green; font-weight: bold } // Name.Function
    .nn    { color: $green } // Name.Namespace
    .nt    { color: $violet } // Name.Tag
    .nv    { color: $violet } // Name.Variable
    .ow    { font-weight: bold } // Operator.Word
    .w     { color: $base2 } // Text.Whitespace
    .mf    { color: $cyan } // Literal.Number.Float
    .mh    { color: $cyan } // Literal.Number.Hex
    .mi    { color: $cyan } // Literal.Number.Integer
    .mo    { color: $cyan } // Literal.Number.Oct
    .sb    { color: $blue } // Literal.String.Backtick
    .sc    { color: $blue } // Literal.String.Char
    .sd    { color: $blue } // Literal.String.Doc
    .s2    { color: $blue } // Literal.String.Double
    .se    { color: $blue } // Literal.String.Escape
    .sh    { color: $blue } // Literal.String.Heredoc
    .si    { color: $red } // Literal.String.Interpol
    .sx    { color: $blue } // Literal.String.Other
    .sr    { color: $cyan } // Literal.String.Regex
    .s1    { color: $blue } // Literal.String.Single
    .ss    { color: $orange } // Literal.String.Symbol
    .bp    { color: $base1 } // Name.Builtin.Pseudo
    .vc    { color: $green } // Name.Variable.Class
    .vg    { color: $yellow } // Name.Variable.Global
    .vi    { color: $cyan } // Name.Variable.Instance
    .il    { color: $cyan } // Literal.Number.Integer.Long
}
