@charset "utf-8";

@import "syntax-highlighting";

body {
    font-family: $body-font;
    background-color: $bg;
    color: $fg;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 12.75pt;
    line-height: 1.5;
}

header {
    margin: 1em 0;
    
    h1 {
	margin: 0.25em 0;
    }

    .meta {
	margin: 0.25em 0;
    }
}

p {
    margin: 1em 0;
}

.wrapper {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
}

blockquote {
    margin-left: 0;
    margin-right: 0;
    padding: 0 1em;
    border-left: .25em solid $fg;
}

table {
    border-spacing: 5px;
    margin-left: auto;
    margin-right: auto;
}

td {
    border: 1px solid $fg;
    padding: 4px 6px 4px 6px;
}

.home img {
    margin-left: auto;
    margin-right: auto;
    display: block;
    image-rendering: pixelated;
}

.posts {
    list-style-type: none;
    padding-left: 0px;
  
    h2 {
	padding-left: 40px;
    }
    
    h3 {
	display: inline;
	a {
	    text-decoration: underline;
	    border-bottom: none;
	}
    }
}

.link-up::before {
    content: "< ";
}

a {
    color: $link-new;
  
    &:link {
	text-decoration: none;
    }

    &:visited {
	color: $link-old;
    }

    &:focus {
	// etc
    }
  
    &:hover {
	border-bottom: 1px solid;
    }

    &:active {
	// etc
    }
}

sup {
    font-size: 12px;
}